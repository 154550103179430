@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap');
/* @import url('https://cdn.quilljs.com/1.2.6/quill.snow.css'); */
html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Poppins', sans-serif !important;
}
.ql-container.ql-snow {
  border: none;
}
/* header css */
.header-container,.fixed-top {
  background: linear-gradient(180deg, #684EFB 0.01%, #5A00DD 100.01%);
}

#basic-navbar-nav>div {
  padding-right: 5%;
}

#basic-navbar-nav a {
  color: #fff;
}
/* header css closed */
a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}


/* extra global  */
.list-group-item {
  border: 0;
}
a:hover {
  text-decoration: none;
}

p {
  line-height: 24px;
  font-size: 16px;
}
article p, li{
  line-height: 28px;
}
.primary {
  background: linear-gradient(180deg, #684efb 0.01%, #5a00dd 100.01%);
}

.card {
  border: 0;
}

.list-group-item {
  border: 0;
}
.loading-btn {
  width: 150px;
  height: 45px;
  display: flex;
  align-items: center;
}
hr {
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid #d5d5d5;
}

h2.section-title {
  color: #202428;
  font-weight: 600;
  font-size: 36px;
}

.card-title {
  font-size: 24px;
  font-weight: 600;
  color: #212529;
}
.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler-icon {
  color: #ffffff;
}
/* features-area */
.features-area .post-meta.d-flex {
  margin-top: -15px !important;
}
.features-area .card-body{
  padding-top: 5px;
}

a {
  cursor: pointer;
}
a:hover {
  color: #202428;
}
span svg {
  cursor: pointer;
}

.slick-slider.tags.w-100.slick-initialized > .slick-list .slick-track div {
  margin: 0 2px;
  width: auto !important;
}

.topis-item span {
  padding-left: 5px;
}

.topis-item {
  min-width: 190px;
  font-weight: 600;
  font-size: 17px;
  border-radius: 15px;
  border: 2px solid #e1e1e1 !important;
  margin: 5px;
  cursor: pointer;
  transition: 0.6s;
  width: auto !important;
  display: inline-block;
  padding: 15px 15px;
  text-transform: capitalize;
  border-radius: 15px !important;
}

.topis-item.active {
  background: #5b05df;
  color: #fff;
}

.topis-item:hover {
  background: #5b05df;
  color: #fff;
}
.slick-prev {
  left: 25px !important;
  z-index: 99999999;
}
.slick-next {
  right: 15px !important;
  z-index: 99999999;
}
.slick-dots li.slick-active button::after,
.slick-dots li.slick-active button:before {
  color: #5a5aff !important;
  opacity: 1 !important;
}

/* comment css */
.comment-area {
  position: fixed;
  height: 97vh;
  overflow-x: hidden; 
  overflow-y: scroll;
  z-index: 100;
}

.comment-container .card-img-top {
  width: 40px !important;
  border-radius: 50%;
  margin-right: 8px;
}
.comment-container h6 {
  font-size: 14px;
  line-height: 10px;
}
.comment-container span {
  font-size: 12px;
}
.comment-container .card-text {
  font-size: 14px;
  margin-bottom: 0;
}
.comment-container .card-body {
  padding: 0 1rem;
}
.comment-row {
  position: sticky;
  width: 30%;
  position: absolute;
  z-index: 999999999999;
  right: 0;
  background: #f8f9fa;
  min-height: 95vh;
}

/* banner css */
section.top-banner {
  min-height: 320px;
}

.banner-content h1 {
  padding-top: 15%;
  font-size: 45px;
  font-weight: 600;
}
/* features area */
.features-area {
  margin-top: 2rem;
}
.features-content .card .card-body .card-title {
  font-weight: 600;
  letter-spacing: 0.0015em;
  font-size: 27px;
}

.post-meta > .list-group-item {
  color: #949494;
}

.post-meta span {
  color: #0c0000;
  font-weight: 400;
}

.hover-area {
  height: 190px;
  position: relative;
}
.hover-area img {
 max-height: 100%;
}

.bottom-area {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0;
  background: rgba(0, 0, 0, 0.6);
  color: black;
  transition: 0.4s ease-out;
}

.hover-area:hover .bottom-area {
  opacity: 0.6;
}

.bottom-area button {
  margin: 0;
  font-size: 16px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  font-weight: 500;
  transform: translateX(-50%) translateY(-50%);
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  padding: 10px 25px;
}

/* popular css start */
.popular-item > div {
  flex-direction: row !important;
}

.popular-item .right {
  width: 50% !important;
}

.popular-content > .mb-4.col-md-6:nth-child(1) .popular-item {
  border-left: 7px solid #ff6d6d;
  border-radius: 10px 0px 0px 10px;
}

.popular-content > .mb-4.col-md-6:nth-child(2) .popular-item {
  border-left: 7px solid #936dff;
  border-radius: 10px 0px 0px 10px;
}

.popular-content > .mb-4.col-md-6:nth-child(3) .popular-item {
  border-left: 7px solid #ffc56d;
  border-radius: 10px 0px 0px 10px;
}

.popular-content > .mb-4.col-md-6:nth-child(4) .popular-item {
  border-left: 7px solid #4dc85a;
  border-radius: 10px 0px 0px 10px;
}

/* popular css closed */

/* post css start */
.post-content .card .card-body .card-title {
  font-weight: 600;
  letter-spacing: 0.0015em;
  font-size: 24px;
  margin-bottom: 0;
}

.post-meta > .list-group-item {
  color: #949494;
}

.post-meta span {
  color: #0c0000;
  font-weight: 400;
}
.card-top {
  height: 160px;
  position: relative;
}
.card-top:hover .bottom-area {
  opacity: 0.6;
}

.read-more a {
  text-decoration: none;
  color: #5d0fe3;
}

.post-title.mb-1 {
  font-weight: 600;
}
/* post css closed */
/* post details */
.post-details .post-title {
  font-size: 36px;
  font-weight: 600;
}

/* footer css */
footer .footer-nav-container .list-group-item {
  background-color: transparent;
  color: #fff;
  font-size: 16px;
}

.footer-container {
  padding-top: 70px;
  padding-bottom: 20px;
}

.footer-container .col-md-4 .list-group-item {
  background-color: transparent;
  color: #fff;
}

.social-container>p{
  margin-top: 7px;
  font-size: 22px;
}
/* RESPONSIVE CSS */

/* Normal desktop :992px. */

@media (min-width: 992px) and (max-width: 1200px) {
  .tags > li.list-group-item {
    font-weight: 600;
    font-size: 17px;
    border-radius: 15px;
    border: 2px solid #e1e1e1;
    margin: 5px;
    padding: 15px 35px;
    cursor: pointer;
    transition: 0.6s;
    width: 200px;
  }
}

@media (max-width: 985px) {
  .hover-area {
    height: 150px;
    position: relative;
  }
}
/* Tablet desktop :768px. */

@media (min-width: 768px) and (max-width: 991px) {
  .card-title {
    font-size: 16px;
    font-weight: 600;
  }
  .post-title.mb-1 {
   font-size: 13px;
  }
  .ml-auto.text-white.navbar-nav {
    text-align: center;
  }
  .post-meta > .list-group-item {
    padding: 0;
    font-size: 12px;
  }
  .tags > li.list-group-item {
    font-weight: 600;
    font-size: 17px;
    border-radius: 15px;
    border: 2px solid #e1e1e1;
    margin: 5px;
    padding: 21px 35px;
    cursor: pointer;
    transition: 0.6s;
    width: 260px;
    line-height: 0;
  }
  .comment-row {
    width: 50%;
    margin-top: 10px;
  }

  .post-content .card-title {
    font-size: 16px !important;
    font-weight: 600;
  }
  .post-content .post-meta.d-flex {
    font-size: 14px !important;
  }
  .card-top  {
    height: 100px;
  }

}

/* small mobile :320px. */

@media (max-width: 767px) {
  .tags {
    flex-direction: column;
  }
  .card-title {
    font-size: 16px;
    font-weight: 600;
  }
  .popular-item .post-meta.d-flex {
    font-size: 10px;
  }
  footer {
    background-position: center;
  }
  .footer-nav-container {
    justify-content: start !important;
  }
  .footer-nav-container.d-flex.justify-content-around > .left .list-group-item {
    padding-left: 0 !important;
    padding-right: 25px;
  }
  .social-container.col-md-4 h4 {
    text-align: start !important;
  }
  .social-container.col-md-4 .d-flex.justify-content-center {
    justify-content: start !important;
  }
  
  .ml-auto.text-white.navbar-nav {
    text-align: center;
  }
  .social-container.col-md-4 {
    margin-top: 15px;
  }
  .navbar-brand {
    margin-right: 0;
  }
  .post-meta > .list-group-item {
    padding: 0;
    font-size: 10px;
  }
  .comment-row {
    width: 100%;
    margin: 0 auto;
    margin-top: 10px;
  }
  .msg-comment-react {
    display: none !important;
  }
  .post-content .card-title {
    font-size: 20px !important;
    font-weight: 600;
  }
  .post-meta > .list-group-item {
    color: #949494;
    padding: 0 10px;
    margin-top: 12px;
  }

}

/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .container {
    width: 450px;
  }
  .post-meta > .list-group-item {
    padding: 0;
    font-size: 14px;
  }
  .comment-row {
    width: 70%;
    margin-top: 10px;
  }
  .tags {
    flex-direction: column;
  }
  .tags > li.list-group-item {
    width: 100%;
  }
  .hover-area {
    height: 130px;
    position: relative;
  }
}

@media only screen and (min-width: 420px) and (max-width: 767px) {
  .post-content > .shadow.card > .card-top img {
    height: 100% !important;
  }
}


@media (max-width: 400px) {
  .topis-item {
    font-weight: 600;
    font-size: 14px;
    border-radius: 15px;
    border: 2px solid #e1e1e1 !important;
    margin: 5px;
    cursor: pointer;
    transition: 0.6s;
    width: auto !important;
    display: inline-block;
    padding: 15px 15px;
    border-radius: 15px !important;
  }
}

@media (max-width: 320px) {
  .navbar-brand {
    width: 75%;
  }
}
@media (min-width: 768px) {
  .features-content {
    display: flex;
    height: 330px;
   }
}


.ql-editor p, .ql-editor ol, .ql-editor ul, .ql-editor pre, .ql-editor blockquote, .ql-editor h1, .ql-editor h2, .ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6 {
  margin: inherit;
}
pre.ql-syntax {
  background-color: #23241f;
  color: #f8f8f2;
  overflow: visible;
  border-radius: 5px;
  padding: 15px;
  font-size: 75%;
}

/* forum css */
  .forum-container {
    background-color: #efeded;
    margin-bottom: -35px;
    padding-bottom: 35px;
  }
  .forum-container .divider {
    height: 2px;
    background: #c7c7c7;
    margin-bottom: 11px;
  }



  /* post share button */
  .fb-share:hover {
    color: #4267B2
  }
  .twitter-share:hover {
    color: #1DA1F2;
  }
  .linkdn-share:hover {
    color:#00a0dc
  }
